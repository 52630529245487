import React from "react";
import logo from "./logo.svg";

import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/900.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.min.css";
import "./custom.css";

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./app/components/LandingPage";
import Opportunities from "./app/modules/opportunity/Opportunities";
import OpportunityDetail from "./app/modules/opportunity/OpportunityDetails";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/opportunities" element={<Opportunities />} />
        <Route
          path="/opportunity-details/:id"
          element={<OpportunityDetail/>}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
