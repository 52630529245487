import * as React from "react";
import circularImage from "../../images/circular.png";

interface OpportunityDetailsHeroProps {
  imageUrl?: string;
  title?: string;
}

export default function OpportunityDetailsHero({
  imageUrl,
  title,
}: OpportunityDetailsHeroProps) {
  const backgroundStyle = {
    backgroundImage: `url(${imageUrl}), url(${circularImage})`,
    backgroundSize: "cover, contain",
    backgroundPosition: "center, top right",
    backgroundRepeat: "no-repeat, no-repeat",
  };

  return (
    <section
      className="container-fluid py-5 mt-5 bg-black text-white text-center"
      id="section-two"
      style={backgroundStyle}
    >
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 col-md-6 text-center">
            <h6 className="fw-bolder text-uppercase mb-3">OPPORTUNITIES</h6>
            <h2 className="mb-4">
              Discover Financing Opportunities to Propel Your Business to Global
              Success
            </h2>
          </div>
        </div>
      </div>
    </section>
  );
}
