import * as React from "react";

export default function About() {
  return (
    <section
      className="container-fluid py-5 mt-5 bg-black text-white"
      id="about"
    >
      <div className="row">
        <div className="col-12">
          <div className="container">
            <div className="row align-items-center">
              {/* Image section with floating behavior only on large screens */}
              <div className="col-12 col-lg-6 order-lg-1 order-2">
                <img
                  className="d-none d-lg-block floating-image"
                  alt=""
                  src="/images/6.png"
                  style={{ width: "100%", height: "auto" }}
                />
                <img
                  className="d-block d-lg-none"
                  alt=""
                  src="/images/6.png"
                  style={{ width: "100%", height: "auto" }}
                />
              </div>

              <div className="col-12 col-lg-6 order-lg-2 order-1">
                <label className="text-uppercase fs-6 my-3">about</label>
                <h3>
                  Revolutionizing Investment <br />
                  Readiness & Fundraising
                </h3>
                <div className="section-description py-3">
                  <p>
                    BLACK is a transformative platform that redefines engagement within the investment
                    ecosystem by seamlessly connecting businesses, investors, and development partners.
                    Acting as a central hub for deal origination, investor support, and strategic advisory services,
                    BLACK enhances the impact of development programs by streamlining workflows, boosting scalability,
                    enabling efficient collaboration, and driving impactful investments for sustainable growth.
                  </p>
                  <p>
                    By centralizing resources and promoting informed decision-making, BLACK empowers stakeholders
                    to engage in meaningful, high-value transactions with ease and transparency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
