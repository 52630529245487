import PageHeader from "./PageHeader";
import Home from "./Home";
import About from "./About";
import Features from "./Features";
import Value from "./Value";
import Story from "./Story";
import FAQs from "./FAQs";
import Footer from "./Footer";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function LandingPage() {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.substring(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);
    return(
        <div className="App">
            <PageHeader/>
            <Home />
            <About />
            <Features />
            <Value />
            <Story  />
            <FAQs />
            <Footer />
        </div>
    )
}