import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import OpportunityDetailsHero from "./OpportunitiesDetailsHero";

// Data structure for opportunities
const opportunities = [
  {
    id: "1",
    title: "Agri-Tech Development Loan",
    description:
      'Mastercard Foundation is seeking companies working on agri-tech solutions such as precision farming, smart irrigation systems, and advanced crop management tools to apply for its "Agri-Tech Development Loan." This financing opportunity aims to support the growth and scaling of innovative technologies that can transform agriculture. By providing access to capital with favorable terms, the Foundation is dedicated to fostering advancements that enhance agricultural productivity, sustainability, and profitability. The loan is designed to help businesses at various stages bring their cutting-edge solutions to market, addressing critical challenges like food security and climate resilience.',
    qualifications: [
      "Strong commitment to innovation in agriculture.",
      "Focus on developing or scaling technologies such as AI, IoT, data analytics, or automation.",
      "Clear business model with a scalable solution.",
      "Evidence of market demand for the product or service.",
      "Strategy for achieving financial sustainability.",
      "Demonstrated positive impact on smallholder farmers and rural communities.",
      "Emphasis on improving productivity, reducing environmental impact, and creating economic opportunities.",
    ],
    deadline: "16th November, 2024",
    location: "Western Uganda",
    logo: "", // Update with a relevant image URL if needed
    fundAmount: "USD 32,500",
    sectors: ["AGRICULTURE", "HEALTH"],
    uploaded: "2 weeks ago",
  },
];

export function OpportunityDetails() {
  const { id } = useParams();
  const navigate = useNavigate();
  const opportunity = opportunities.find(
    (opportunity) => opportunity.id === id
  );

  if (!opportunity) {
    return <div>Opportunity not found</div>;
  }

  return (
    <div>
      <OpportunityDetailsHero imageUrl={""} title={opportunity.title} />
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-8">
            <div className="card p-4">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-link p-0 me-2"
                    onClick={() => navigate(-1)}
                  >
                    <i
                      className="bi bi-arrow-left"
                      style={{ fontSize: "1.5rem", color: "black" }}
                    ></i>
                  </button>
                  <h1>{opportunity.title}</h1>
                </div>
                <button className="btn btn-primary custom-btn">
                  Apply now
                </button>
              </div>
              <hr className="full-width-hr" />
              <h2>Description</h2>
              <p>{opportunity.description}</p>
              <h2>Qualifications and Requirements</h2>
              <ul>
                {opportunity.qualifications.map((qualification, index) => (
                  <li key={index}>{qualification}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card p-3 attached-card">
              <div className="d-flex align-items-center mb-3">
                <img
                  src={opportunity.logo}
                  alt="Logo"
                  className="img-fluid me-3 custom-img"
                  style={{ width: "50px", height: "50px" }}
                />
                <h3 className="mb-0">{opportunity.title}</h3>
              </div>
              <hr className="full-width-hr" />
              <p>
                <strong>Fund Amount</strong>
                <br />
                <span className="badge custom-badge me-1">
                  {opportunity.fundAmount}
                </span>
              </p>
              <hr className="full-width-hr" />
              <p>
                <strong>Sector(s)</strong>
                <br />
                {opportunity.sectors.map((sector, index) => (
                  <span key={index} className="badge bg-primary me-1">
                    {sector}
                  </span>
                ))}
              </p>
              <hr className="full-width-hr" />
              <p>
                <strong>Location Scope</strong> <br />
                {opportunity.location}
              </p>
              <hr className="full-width-hr" />
              <p>
                <strong>Deadline</strong>
                <br /> {opportunity.deadline}
              </p>
              <hr className="full-width-hr" />
              <p>
                <strong></strong>
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
