import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { MultiSelect } from "../../shared/components/MultiSelect";
import { countries, ICountryValue } from "../../../../utils/countries";
import { INameIdPair } from "../../shared/components/INameIdPair";
import { currencyNumberPairs } from "../../../../utils/currencies";
import NumberFormatter from "../../shared/components/NumberFormatter";

interface Props {
  showModal: boolean;
  handleClose: () => void;
  companySectorsList: INameIdPair[];
  entities?: string;
}

export const ListPageFilters: React.FC<Props> = ({
  showModal,
  handleClose,
  companySectorsList,
  entities = "Opportunities",
}) => {
  const mapIdsToObjects = (
    ids: string[],
    list: INameIdPair[]
  ): INameIdPair[] => {
    return ids
      .map((id) => list.find((item) => item.id === id))
      .filter(Boolean) as INameIdPair[];
  };

  const mapCountryNamesToIdNamePairObjects = (
    names: string[],
    list: ICountryValue[]
  ): INameIdPair[] => {
    const selectedCountries = names
      .map((name) =>
        list.find((item) => item.label.toLocaleLowerCase() === name)
      )
      .filter(Boolean) as ICountryValue[];
    return selectedCountries.map((country) => ({
      id: country.value,
      name: country.label,
    }));
  };

  const FiltersValidationSchema = Yup.object().shape({
    countries: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    ),
    startDatePostedRange: Yup.date().nullable(),
    endDatePostedRange: Yup.date()
      .nullable()
      .when("startDatePostedRange", (startDatePostedRange, schema) => {
        return startDatePostedRange
          ? schema.min(
              Yup.ref("startDatePostedRange"),
              "To Date must be greater than From"
            )
          : schema;
      }),
    sectors: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(),
        name: Yup.string().required(),
      })
    ),
    scope: Yup.string(),
  });

  const initialValues = {
    countries: mapCountryNamesToIdNamePairObjects([], countries),
    startDatePostedRange: "",
    endDatePostedRange: "",
    sectors: mapIdsToObjects([], companySectorsList),
    scope: "",
  };

  const handleSubmit = (values: any) => {
    handleClose();
  };

  const handleClearButtonClick = () => {
    handleClose();
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      id="kt_assign_project"
      aria-hidden="true"
      centered
      dialogClassName="h-auto me-0 pe-0 custom-modal"
    >
      <div role="document">
        <Formik
          initialValues={initialValues}
          validationSchema={FiltersValidationSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue, errors, touched, isValid }) => {
            return (
              <Form>
                <div className="modal-header pb-2 d-flex justify-content-between align-items-center">
                  <h5 className="modal-title">Filter</h5>
                  <img
                    onClick={handleClose}
                    alt="close icon"
                    className="no-outline cursor-pointer"
                    src="/images/close-arrow.svg"
                  />
                </div>
                <div className="modal-body pt-2">
                  <div className="fv-row">
                    <div className="col-md-12 mb-3">
                      <span className="fs-7 text-black">Location</span>
                      <MultiSelect
                        selectedValues={values.countries}
                        options={countries.map((country) => ({
                          id: country.value,
                          name: country.label,
                        }))}
                        setOption={(selectedOptions) =>
                          setFieldValue("countries", selectedOptions)
                        }
                      />
                    </div>
                  </div>
                  <div className="fv-row">
                    <div className="col-md-12 mb-3">
                      <span className="fs-7 text-black">Scope</span>
                      <Field
                        type="text"
                        className="form-control form-control-sm min-input-field-height rounded-3 custom-focus-outline"
                        name="scope"
                        placeholder=""
                      />
                      {touched?.scope && (
                        <div className="text-danger mt-2">{errors?.scope}</div>
                      )}
                    </div>
                  </div>
                  <div className="fv-row row mb-3">
                    <span className="fs-7 text-black">Date Posted</span>
                    <div className="col-md-6 ">
                      <span className="fs-6">From</span>
                      <Field
                        type="date"
                        className="form-control form-control-sm min-input-field-height rounded-3 custom-focus-outline"
                        name="startDatePostedRange"
                        placeholder=""
                        onFocus={(e:React.FocusEvent<HTMLInputElement>) => e.target.showPicker()}
                      />
                      {touched?.startDatePostedRange && (
                        <div className="text-danger mt-2">
                          {errors?.startDatePostedRange}
                        </div>
                      )}
                    </div>
                    <div className="col-md-6">
                      <span className="fs-6">To</span>
                      <Field
                        type="date"
                        className="form-control form-control-sm min-input-field-height rounded-3 custom-focus-outline"
                        name="endDatePostedRange"
                        placeholder=""
                        onFocus={(e:React.FocusEvent<HTMLInputElement>) => e.target.showPicker()}
                      />
                      {touched?.endDatePostedRange && (
                        <div className="text-danger mt-2">
                          {errors?.endDatePostedRange}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="fv-row">
                    <div className="col-md-12 mb-3">
                      <span className="fs-7 text-black">Sector(s)</span>
                      <MultiSelect
                        selectedValues={values.sectors}
                        options={companySectorsList}
                        setOption={(selectedOptions) =>
                          setFieldValue("sectors", selectedOptions)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="modal-footer mt-0 d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-danger"
                    disabled={!isValid}
                  >
                    Apply
                  </button>
                  <button
                    type="reset"
                    className="btn btn-light"
                    onClick={handleClearButtonClick}
                  >
                    Clear
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
