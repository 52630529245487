import * as React from "react";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";

interface ButtonWithIconProps {
  name: string;
  icon: React.ReactElement;
  isActive: boolean;
  handleClick: () => void;
}

const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  name,
  icon,
  isActive,
  handleClick,
}) => {

  return (
    <div
    className={`btn rounded-5 bg-white mx-2 my-1 px-4 h-35 d-flex align-items-center ${
      isActive ? "text-danger" : ""
    }`}
      onClick={handleClick}
    >
      <IconButton size="small" edge="start" color="inherit" aria-label={name}>
        {icon}
      </IconButton>
      <span className="ms-0">{name}</span>
    </div>
  );
};

export default ButtonWithIcon;
