import { ReactElement } from "react";

import {
  AllInbox,
  Agriculture,
  School,
  Paid,
  HealthAndSafety,
  Factory,
  Computer,
  FilterList,
  Gavel,
  Build,
  Business,
  Domain,
  LocalOffer,
  Restaurant,
  DirectionsCar,
  Storefront,
  AttachMoney,
  ShoppingCart,
  Terrain,
  EmojiTransportation,
  Power,
  Devices,
  LocalShipping,
  Construction,
} from "@mui/icons-material";

export interface ISectorCategory {
  name: string;
  icon: ReactElement;
}

export const sectorCategoryIcons: ISectorCategory[] = [
  { name: "Agriculture", icon: <Agriculture fontSize="small" /> },
  { name: "Education", icon: <School fontSize="small" /> },
  { name: "Finance", icon: <Paid fontSize="small" /> },
  { name: "Health", icon: <HealthAndSafety fontSize="small" /> },
  { name: "Infrastructure", icon: <Build fontSize="small" /> },
  { name: "Legal", icon: <Gavel fontSize="small" /> },
  { name: "Manufacturing", icon: <Factory fontSize="small" /> },
  { name: "Technology", icon: <Computer fontSize="small" /> },
  { name: "Media and Technology", icon: <Devices fontSize="small" /> },
  { name: "Public-Private Partnerships", icon: <Business fontSize="small" /> },
  { name: "Telecommunications", icon: <LocalShipping fontSize="small" /> },
  {
    name: "Tourism, Retail and Property",
    icon: <Storefront fontSize="small" />,
  },
  { name: "Construction", icon: <Construction fontSize="small" /> },
  { name: "Energy", icon: <Power fontSize="small" /> },
  { name: "Financial Services", icon: <AttachMoney fontSize="small" /> },
  { name: "Food & Beverage", icon: <Restaurant fontSize="small" /> },
  { name: "Mining", icon: <Terrain fontSize="small" /> },
  { name: "Real Estate", icon: <Domain fontSize="small" /> },
  { name: "Services", icon: <ShoppingCart fontSize="small" /> },
  {
    name: "Tourism & Hospitality",
    icon: <EmojiTransportation fontSize="small" />,
  },
  { name: "Trade", icon: <LocalOffer fontSize="small" /> },
  { name: "Transport", icon: <DirectionsCar fontSize="small" /> },
];
