import * as React from "react";


export default function Home() {
  return (
    <section className="container py-5 mt-5" id="home">
      <div className="row align-items-center home">
        <div className="col-12 col-lg-6 pe-lg-5 mb-4 mb-lg-0 align-self-lg-start pt-7 call-to-action">
          <h1 className="section-title fs-3 fs-md-4 fs-lg-5">
            Unlocking Capital for Business Growth
          </h1>
          <div className="section-description fs-md-5">
            A transformative platform where global financing meets growth and opportunity, connecting businesses with
            capital, insights, and support for sustainable expansion. More than deal-making, this is a complete
            ecosystem empowering innovation and lasting success in a dynamic global market.
          </div>
          <a href="opportunities" className="btn btn-dark my-3 my-lg-5">
            See Opportunities
          </a>
        </div>
        <div className="col-12 col-lg-6">
          <div className="row g-2">
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 1"
                src="/images/1.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 2"
                src="/images/2.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 3"
                src="/images/3.png"
                className="img-fluid square-img"
              />
            </div>
            <div className="col-6 d-flex justify-content-center align-items-center">
              <img
                alt="Collage Image 4"
                src="/images/4.png"
                className="img-fluid square-img"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
