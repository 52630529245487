import PageHeader from "../../components/PageHeader";
import React from "react";
import Footer from "../../components/Footer";
import {OpportunityDetails} from "../../components/OpportunityDetailsScreen";
import OpportunityFooter from "../../components/OpportunityFooter";

function OpportunityDetail() {
    return(
        <div>
            <PageHeader/>
            <OpportunityDetails/>
        </div>
    );
}
export default OpportunityDetail;