import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function Features() {
  return (
    <section
      className="d-flex flex-column align-items-center justify-content-center mt-5"
      id="features"
    >
      <div className="pe-lg-5 mb-3 d-flex flex-column align-items-center justify-content-center px-3 features-text">
        <label className="text-uppercase fs-6 my-3 fw-bold w-100">
          FEATURES UNDER IRFP
        </label>
        <h4 className="max-width-700px">
          The Investment Readiness and Fundraising Program
        </h4>
        <div className="section-description py-3 max-width-900px">
          The Investment Readiness and Fundraising Program is designed to prepare businesses for successful
          fundraising by equipping them with the tools, knowledge, and strategic insights needed to attract
          investors and secure capital. This program focuses on helping businesses assess their current operations,
          identify gaps, and strengthen their business models to meet the expectations of potential investors.
          Participants are guided through key aspects of investment readiness, including financial planning,
          market positioning, value proposition development, and pitch preparation.
        </div>
      </div>
      {/* Collage Section */}
      <div className="d-flex flex-column flex-md-row align-items-center align-items-md-end justify-content-center px-3 gap-4">
        <div className="bg-pink p-3 rounded-3 w-100 max-width-350 min-height-200px">
          <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
            <img
              alt="pipeline icon"
              className=""
              src={`${process.env.PUBLIC_URL}/images/pipeline-image.svg`}
            />
            <h6>Pipeline Development</h6>
          </div>
          <p className="card-text h-100px">
            IRFP assists investors build a pipeline and identify businesses and
            projects for investment that align with their core values and goals
            for investment.  
          </p>
          {/* Arrow Icon */}
          <div className="d-flex justify-content-end ">
            <div className="border border-2 rounded-circle border-white">
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
        <div className="bg-black p-3 rounded-3 w-100 max-width-350">
          <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
            <img
              alt="globe"
              className=""
              src={`${process.env.PUBLIC_URL}/images/globe-image.svg`}
            />
            <h6>Assessment of Business Needs</h6>
          </div>
          <p className="card-text">
            We conduct thorough evaluations to identify key strategic areas for
            improvement within businesses.
          </p>
          <p className="card-text">
            This is based on the experience we have built working with different
            investors and businesses. Some of the aspects covered include
            financial stability, governance, market positioning, sales strategy,
            among others.  
          </p>
          {/* Arrow Icon */}
          <div className="d-flex justify-content-end ">
            <div className="border border-2 rounded-circle border-white">
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
        <div className="bg-pink p-3 rounded-3 w-100 max-width-350 min-height-200px">
          <div className="d-flex gap-2 justify-content-start align-items-center mb-2">
            <img
              alt="checked task icon"
              className=""
              src={`${process.env.PUBLIC_URL}/images/checked-task-image.svg`}
            />
            <h6>Pipeline Development</h6>
          </div>
          <p className="card-text h-100px">
            We facilitate introductions and engagements between investors and
            businesses whose goals are aligned.
          </p>
          {/* Arrow Icon */}
          <div className="d-flex justify-content-end ">
            <div className="border border-2 rounded-circle border-white">
              <ArrowForwardIcon />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
