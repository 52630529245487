import { useEffect, useRef, useState } from "react";
import ButtonWithIcon from "./ButtonWithIcon";

import { AUTH_TOKEN_KEY, env } from "../../data/constants";
import { sectorCategoryIcons } from "../../data/sectorCategories";

import { INameIdPair } from "../modules/shared/components/INameIdPair";

import { AllInbox, FilterList } from "@mui/icons-material";

import { ListPageFilters } from "../modules/opportunity/components/Filters";

import Skeleton from "@mui/material/Skeleton";

const OpportunitiesHeroSection = () => {
  const [sectorCategories, setSectorCategories] = useState<
    { name: string; icon: any }[]
  >([]);

  const [activeCategory, setActiveCategory] = useState<string>("All");
  const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);

  const [isFetching, setIsFetching] = useState<boolean>(true); // New fetching state

  const sectorsRef = useRef<INameIdPair[]>([]);

  const handleCategoryClick = (categoryName: string) => {
    setActiveCategory(categoryName);
    if (categoryName === "More Filters") {
      setShowFiltersModal(!showFiltersModal);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const accessToken = localStorage.getItem(AUTH_TOKEN_KEY) || "";
      const getCompanySectorsUrl = env.CompanyUrl
        ? `${env.CompanyUrl}/sectors`
        : "";

      const response = await fetch(getCompanySectorsUrl, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        sectorsRef.current = await response.json();

        const newCategories = generateSectorCategoriesWithIcons(
          sectorsRef.current
        );

        setSectorCategories(newCategories);
      } else {
        console.error("Failed to fetch sectors:", response.statusText);
      }

      setIsFetching(false); // Set fetching state to false after data is fetched
    };

    fetchData();
  }, []);

  const generateSectorCategoriesWithIcons = (sectors: INameIdPair[]) => {
    const newCategories = [
      { name: "All", icon: <AllInbox fontSize="small" /> },
    ];

    const matchedCategories = sectors
      .map((sector) => {
        const matchedCategory = sectorCategoryIcons.find((category) =>
          category.name
            .toLowerCase()
            .startsWith(sector.name.slice(0, 4).toLowerCase())
        );

        return matchedCategory
          ? { name: sector.name, icon: matchedCategory.icon as any }
          : null;
      })
      .filter(Boolean) as { name: string; icon: any }[];
    newCategories.push(...matchedCategories);

    // eslint-disable-next-line react/jsx-no-undef
    newCategories.push({
      name: "More Filters",
      icon: <FilterList fontSize="small" />,
    });

    return newCategories;
  };

  const handleFiltersModal = () => {
    setShowFiltersModal(!showFiltersModal);
  };

  return (
    <section
      className="container-fluid pt-5 mt-5 bg-black text-white"
      id="section-three"
    >
      <div className="d-flex border-bottom pb-3 flex-column align-items-center text-center justify-content-center">
        <div className="opportunities-hero">
          <h6 className="fw-bolder text-smaller text-start text-md-center heading">
            OPPORTUNITIES
          </h6>

          <div className="text-start text-md-center description max-width-700px ">
            <span className="fs-2">
              Discover financing opportunities to propel your business to global success
            </span>
          </div>
        </div>

        <div className="input-group w-100 opportunities-search d-flex justify-content-center py-3 max-width-700px ">
          <input
            type="text"
            className="form-control py-3 ps-5 rounded-start-5"
            placeholder="Search Opportunity..."
            aria-label="Search Opportunity..."
            aria-describedby="basic-addon2"
          />

          <button
            className="btn btn-danger rounded-start-0 rounded-end-5 py-3 px-5"
            type="button"
          >
            Search
          </button>
        </div>

        <div className="d-flex flex-wrap justify-content-center py-3 text-hover-danger ">
          {isFetching
            ? Array.from({ length: 3 }).map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rounded"
                  width={100}
                  height={35}
                  sx={{ bgcolor: "white", borderRadius: "17.5px", marginRight:2 }}
                />
              ))
            : sectorCategories.map((category, index) => (
                <div
                  key={index}
                  className={`category-item category-item-${index} ${
                    category.name !== "More Filters" ? "" : "more-filters"
                  }`}
                >
                  <ButtonWithIcon
                    name={category.name}
                    icon={category.icon}
                    isActive={activeCategory === category.name}
                    handleClick={() => handleCategoryClick(category.name)}
                  />
                </div>
              ))}
        </div>
      </div>
      <ListPageFilters
        showModal={showFiltersModal}
        handleClose={handleFiltersModal}
        companySectorsList={sectorsRef.current}
      />
    </section>
  );
};

export default OpportunitiesHeroSection;
