import PageHeader from "../../components/PageHeader";
import React from "react";
import Footer from "../../components/Footer";
import OpportunitiesHeroSection from "../../components/OpportunitiesHeroSection";
import OpportunitiesList from "../../components/OpportunitiesList";


function Opportunities() {
  return (
    <div>
      <PageHeader />
      <OpportunitiesHeroSection />
      <OpportunitiesList />
      <Footer />
    </div>
  );
}
export default Opportunities;
