import * as React from "react";

import bgImage1 from "../../images/7.jpg";
import bgImage2 from "../../images/8.jpg";
import bgImage3 from "../../images/9.jpg";

export default function Value() {
  return (
    <section className="container py-5 mt-5" id="value">
      <div className="row align-items-center">
        <div className="col-12">
          <label className="text-uppercase fs-6 my-3">OUR VALUE</label>
          <h4 className="pb-4">Why people choose Project Black</h4>

          <div className="row">
            <div className="col-12 col-lg-4 rounded-5">
              <div
                className="collage-card text-white mx-auto d-block"
                style={{ backgroundImage: `url(${bgImage1})` }}
              >
                <div className="p-4 overlay">
                  <h5 className="pb-3">Investors</h5>
                  <p className="pb-3">
                    Project Black streamlines the process of identifying and
                    engaging with rel bankable businesses and projects aligned
                    with investors’ core values and goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 rounded-3">
              <div
                className="collage-card text-white mx-auto d-block"
                style={{ backgroundImage: `url(${bgImage2})` }}
              >
                <div className="p-4 overlay">
                  <h5 className="pb-3">Businesses</h5>
                  <p className="pb-3">
                    Project Black is an opportunity to engage with multiple
                    global financiers who offer capital at favourable terms
                    aligned with your business interests.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 rounded-3">
              <div
                className="collage-card text-white mx-auto d-block"
                style={{ backgroundImage: `url(${bgImage3})` }}
              >
                <div className="p-4 overlay">
                  <h5 className="pb-3">Service Providers</h5>
                  <p className="pb-3">
                    Project Black is an opportunity to expand your client base
                    and support multiple businesses and investors to close
                    deals.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
